import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "searching-for-data"
    }}>{`Searching for Data`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`You can search for Capella SAR data using the catalog service. Capella's catalog follows the `}<a parentName="p" {...{
        "href": "https://stacspec.org/"
      }}>{`Spatio Temporal Asset Catalog (STAC)`}</a>{` specification. The catalog service allows you to search the Capella catalog using filters for location, time, collections, metadata properties, and scene ids. Items returned from a catalog search can be subsequently ordered and downloaded.`}</p>
    <h2 {...{
      "id": "search-filters"
    }}>{`Search Filters`}</h2>
    <p>{`Before submitting a search, it's important to understand the various filters that can be used to refine results from a search.`}</p>
    <h3 {...{
      "id": "bbox-filter"
    }}>{`BBOX Filter`}</h3>
    <pre><code parentName="pre" {...{}}>{`"bbox": [-110,39.5,-105,40.5]
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`bbox`}</inlineCode>{` filter can be used to filter search results to items that intersect with a bounding box. Only features that have a geometry that intersects the bounding box are selected. The bounding box is provided as four or six numbers, depending on whether the coordinate reference system includes a vertical axis (elevation or depth):`}</p>
    <ul>
      <li parentName="ul">{`Lower left corner, coordinate axis 1`}</li>
      <li parentName="ul">{`Lower left corner, coordinate axis 2`}</li>
      <li parentName="ul">{`Lower left corner, coordinate axis 3 (optional)`}</li>
      <li parentName="ul">{`Upper right corner, coordinate axis 1`}</li>
      <li parentName="ul">{`Upper right corner, coordinate axis 2`}</li>
      <li parentName="ul">{`Upper right corner, coordinate axis 3 (optional)`}</li>
    </ul>
    <p>{`The coordinate reference system of the values is WGS84 longitude/latitude unless a different coordinate reference system is specified in the parameter `}<inlineCode parentName="p">{`bbox-crs`}</inlineCode>{`.`}</p>
    <p>{`For WGS84 longitude/latitude the values are in most cases the sequence of minimum longitude, minimum latitude, maximum longitude and maximum latitude. However, in cases where the box spans the antimeridian the first value (west-most box edge) is larger than the third value (east-most box edge).`}</p>
    <h3 {...{
      "id": "intersects-filter"
    }}>{`Intersects Filter`}</h3>
    <pre><code parentName="pre" {...{}}>{`"intersects": {
    "type": "Point",
      "coordinates": [
      -113.5546875,
      51.17934297928927
    ]
  }
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`intersects`}</inlineCode>{` filter can be used to filter search results to items that intersect with a GeoJSON geometry that can be a point, line, or polygon. Note that only the `}<inlineCode parentName="p">{`geometry`}</inlineCode>{` component of the GeoJSON is used. The coordinate reference system of the values is WGS84 longitude/latitude. For WGS84 longitude/latitude the values are in most cases the sequence of minimum longitude, minimum latitude, maximum longitude and maximum latitude. However, in cases where the box spans the antimeridian the first value (west-most box edge) is larger than the third value (east-most box edge).`}</p>
    <h3 {...{
      "id": "datetime-filter"
    }}>{`Datetime Filter`}</h3>
    <pre><code parentName="pre" {...{}}>{`"datetime": "2018-02-12T00:00:00Z/2018-03-18T12:31:12Z"
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`datetime`}</inlineCode>{` filter will constrain search results to scenes that have been collected within a certain period of time. This can be either a date-time or an interval, open or closed. Date and time expressions adhere to RFC 3339. Open intervals are expressed using double-dots.`}</p>
    <ul>
      <li parentName="ul">{`A date-time: "2018-02-12T23:20:50Z"`}</li>
      <li parentName="ul">{`A closed interval: "2018-02-12T00:00:00Z/2018-03-18T12:31:12Z"`}</li>
      <li parentName="ul">{`Open intervals: "2018-02-12T00:00:00Z/.." or "../2018-03-18T12:31:12Z"`}</li>
    </ul>
    <p>{`Capella uses UTC time for items within the catalog.`}</p>
    <h3 {...{
      "id": "collections-filter"
    }}>{`Collections Filter`}</h3>
    <pre><code parentName="pre" {...{}}>{`"collections": ["capella-geo","capella-slc"]
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`collections`}</inlineCode>{` filter will indicate that the search should only occur within a specific collection or collections. The filter requires an array of strings that correspond with the identifiers of the collections to be searched.`}</p>
    <h3 {...{
      "id": "ids-filter"
    }}>{`Ids Filter`}</h3>
    <pre><code parentName="pre" {...{}}>{`"ids": ["CAPELLA_ARL_SM_SLC_VV_20190927234024_20190927234124", "CAPELLA_ARL_SL_SLC_VV_20190927234024_20190927234124"]
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`ids`}</inlineCode>{` filter allows a user to search for specific items within the catalog. The filter requires an array of strings that correspond with the identifiers of the products that the user would like to find. When this filter is used, other filters are ignored.`}</p>
    <h3 {...{
      "id": "query-filter"
    }}>{`Query Filter`}</h3>
    <pre><code parentName="pre" {...{}}>{`"query": {"sar:instrument_mode": {"eq": "stripmap"},"sar:product_type": {"eq":"GEO"}}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`query`}</inlineCode>{` filter will be used to constrain results to scenes that match the specific properties described within the filter. This filter is defined using key/value pairs that describe which metadata property to filter and the comparison operation to perform for that property. The query filter can be used for most STAC metadata fields, including:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`sar:instrument_mode`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sar:pixel_spacing_range`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sar:pixel_spacing_azimuth`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sar:looks_range`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sar:looks_azimuth`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sar:looks_equivalent_number`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sar:resolution_range`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sar:resolution_azimuth`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`capella:squint_angle`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sar:observation_direction`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`capella:collect_id`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`start_datetime`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`end_datetime`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`platform`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sar:product_type`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sat:orbit_state`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`view:incidence_angle`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`instruments`}</inlineCode></li>
    </ul>
    <p>{`The comparison operation is defined using equals (`}<inlineCode parentName="p">{`eq`}</inlineCode>{`), less-than (`}<inlineCode parentName="p">{`lt`}</inlineCode>{`), less-than-equal-to (`}<inlineCode parentName="p">{`lte`}</inlineCode>{`), greater-than (`}<inlineCode parentName="p">{`gt`}</inlineCode>{`), or greater-than-equal-to (`}<inlineCode parentName="p">{`gte`}</inlineCode>{`), and the value for comparison.`}</p>
    <h2 {...{
      "id": "submitting-a-search"
    }}>{`Submitting a Search`}</h2>
    <p>{`To submit a search to the catalog with full filtering capabilties, you will `}<inlineCode parentName="p">{`POST`}</inlineCode>{` the desired search filters to the catalog search endpoint: `}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.capellaspace.com/catalog/search

{
    "bbox": [],
    "intersects": {},
    "datetime": "",
    "collections": [],
    "ids": [], 
    "query": {},
    "sortby": [],
    "limit": 50
}
`}</code></pre>
    <p>{`You can use `}<inlineCode parentName="p">{`sortby`}</inlineCode>{` to sort the returned items by a particular metadata property. For example, you can sort the results by the start datetime of the collect in descending order:`}</p>
    <pre><code parentName="pre" {...{}}>{`"sortby": [{"field": "properties.datetime","direction": "desc"}, {"field": "collection","direction": "desc"}]
`}</code></pre>
    <p>{`Alternatively, you can use a shorted form:`}</p>
    <pre><code parentName="pre" {...{}}>{`"sortby": "-properties.datetime"
`}</code></pre>
    <p>{`You can also limit the number of results returned per page by using `}<inlineCode parentName="p">{`limit`}</inlineCode>{`, where the value is an integer indicating the desired number of results. The default is 10.`}</p>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information on submitting a search and the results of a search, see the API reference for the `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/catalog"
      }}>{`Catalog Service`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      